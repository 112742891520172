import React from 'react'

const FAV_LIST = [27571001]

function MusicPlayer() {
  return (
    <>
      {/* <audio
        controls
        src="https://music.163.com/song/media/outer/url?id=27571001.mp3 "
      ></audio> */}
    </>
  )
}

export default React.memo(MusicPlayer)
